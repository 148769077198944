import { type FieldMetadata, useInputControl } from '@conform-to/react'
import * as React from 'react'

import { useId, useState } from 'react'
import { cn } from '#app/utils/misc.tsx'
import { ErrorList, type ListOfErrors } from '../forms.tsx'
import { Button, type ButtonProps } from './button.tsx'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from './command.tsx'
import { Icon, type IconName } from './icon.tsx'
import { Label } from './label.tsx'
import { Popover, PopoverContent, PopoverTrigger } from './popover.tsx'

export interface Props<Data, Name, Value> {
	className?: string
	contentClassName?: string
	defaultOpen?: boolean
	innerContentClassName?: string
	children: (option: Data) => React.ReactNode
	disabled?: boolean
	emptyText?: string
	id: string
	onSelect: (value: Value, option?: Data) => void | boolean
	options: Data[]
	renderLabel: (option?: Data) => React.ReactNode
	renderSearchInput?: React.ReactNode
	searchPlaceholderText?: string
	state?: 'loading' | 'idle' | 'submitting'
	value?: string
	valueKey: Name
	variant?: ButtonProps['variant']
	size?: ButtonProps['size']
	icon?: IconName | null
}

export function Combobox<
	Data,
	Name extends keyof Data,
	Value extends Data[Name],
>({
	className,
	contentClassName,
	defaultOpen = false,
	innerContentClassName,
	children,
	emptyText = 'No options found',
	disabled,
	id,
	onSelect = (value: Value, option?: Data) => {},
	options,
	renderLabel,
	renderSearchInput,
	searchPlaceholderText = 'Search',
	state = 'idle',
	value,
	valueKey,
	variant = 'outline',
	size,
	icon = 'chevrons-up-down',
}: Props<Data, Name, Value>) {
	const [open, setOpen] = useState(defaultOpen)

	const selectedOption = options.find(o => `${o[valueKey]}` === `${value}`)

	const handleSelect = (value: Value, option: Data) => () => {
		const shouldClose = onSelect(value, option)

		if (typeof shouldClose === 'boolean' && !shouldClose) {
			return
		}

		setOpen(false)
	}

	const Loading = (
		<div className="h-4 min-w-[5rem] animate-pulse rounded-full bg-muted" />
	)

	return (
		<Popover open={open} defaultOpen={defaultOpen} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					disabled={disabled}
					size={size}
					variant={variant}
					role="combobox"
					aria-expanded={open}
					className={cn('justify-between', className)}
				>
					{state === 'loading' ? Loading : renderLabel(selectedOption)}
					{icon !== null ? (
						<Icon name={icon} className="ml-2 h-4 w-4 shrink-0 opacity-50" />
					) : null}
				</Button>
			</PopoverTrigger>
			<PopoverContent id={id} className={cn('p-0', contentClassName)}>
				<Command
					className={cn(
						renderSearchInput === null ? 'max-h-[50vh]' : 'max-h-[25vh] ',
						'p-0 sm:max-h-[50vh] md:h-auto',
						innerContentClassName,
					)}
				>
					{renderSearchInput !== undefined ? (
						renderSearchInput
					) : (
						<CommandInput id="search" placeholder={searchPlaceholderText} />
					)}
					<CommandEmpty>
						{state === 'loading' ? Loading : emptyText}
					</CommandEmpty>
					<CommandGroup>
						{options.map(option => (
							<CommandItem
								key={JSON.stringify(option[valueKey])}
								onSelect={handleSelect(option[valueKey] as Value, option)}
								className="flex justify-between"
								disabled={(option as any).disabled}
							>
								{children(option)}
								{value === option[valueKey] ? (
									<Icon
										name="check"
										className={cn(
											'mx-2 h-4 w-4',
											value === option[valueKey] ? 'opacity-100' : 'opacity-0',
										)}
									/>
								) : null}
							</CommandItem>
						))}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	)
}

interface FieldProps<Data, Name, Value> {
	name: string
	form: string
	id: string
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
	className?: string
	contentClassName?: string
	innerContentClassName?: string
	children: (option: Data) => React.ReactNode
	disabled?: boolean
	emptyText?: string
	onSelect?: (value: Value) => void | boolean
	options: Data[]
	renderLabel: (option?: Data) => React.ReactNode
	renderSearchInput?: React.ReactNode
	searchPlaceholderText?: string
	state?: 'loading' | 'idle' | 'submitting'
	value?: string
	defaultValue?: string
	valueKey: Name
	variant?: ButtonProps['variant']
	errors?: ListOfErrors
}

export function getComboboxProps<Schema>({
	key,
	name,
	formId,
	value,
}: FieldMetadata<Schema, any, any>) {
	return {
		id: key || `${formId}-${name}`,
		name,
		form: formId,
		value,
		defaultValue: value,
	}
}

export function ComboboxField<
	Data,
	Name extends keyof Data,
	Value extends Data[Name],
>({
	name,
	form,
	labelProps,
	errors,
	...comboboxProps
}: FieldProps<Data, Name, Value>) {
	const fallbackId = useId()
	const id = comboboxProps.id || fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	const input = useInputControl({
		key: id,
		name: name,
		formId: form,
		initialValue: comboboxProps.defaultValue
			? comboboxProps.defaultValue
			: undefined,
	})

	return (
		<div className="flex flex-col items-start gap-1">
			{labelProps ? <Label htmlFor={id} {...labelProps} /> : null}
			{comboboxProps.defaultValue && !input.value ? (
				<input
					type="hidden"
					name={name}
					defaultValue={comboboxProps.defaultValue}
				/>
			) : null}
			<Combobox<Data, Name, Value>
				{...comboboxProps}
				className={cn(
					!comboboxProps.value ? 'font-normal text-muted-foreground' : '',
					comboboxProps.className,
				)}
				id={id}
				onSelect={value => {
					comboboxProps.onSelect?.(value)
					input.change(String(value))
				}}
			/>
			{errorId ? (
				<div className="pt-1">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}
