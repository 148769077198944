export const CURRENT_SEASON = '2024-25'
export const CURRENT_PRE_SEASON_ID = '00124'
export const CURRENT_SEASON_ID = '00224'
export const CURRENT_PLAYOFF_SEASON_ID = '00423'
export const MOST_RECENT_DRAFT_YEAR = 2024
export const MOST_RECENT_DRAFT_SEASON = '2024-2025'

export const DEFAULT_PATH = '/team/40/overview'

// Salary Cap
export const CURRENT_BUDGET_SEASON = '2024-2025'
export const CURRENT_BUDGET_YEAR = 2024

// App
export const OMIT_USERS = [
	'agb@accel.com',
	'shanefenske1@gmail.com',
	'shane.r.fenske@gmail.com',
	'alecmmoore@gmail.com',
	'abhijit.brahme@utahjazz.com',
]
